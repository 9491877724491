import useProgressOverviewV6 from "@core/services/nocd-api/clinician-dashboard/queries/useProgressOverviewV6";
import { ProgressOverviewV6 } from "@core/services/nocd-api/clinician-dashboard/types";
import MuiLink from "@core/ui/MuiLink";
import removeDecimalsIfInt from "@core/utils/removeDecimalsIfInt";
import { InformationCircleIcon } from "@heroicons/react/24/outline";
import { Bars2Icon, PlusIcon } from "@heroicons/react/24/solid";
import CalculatorIcon from "@heroicons/react/24/solid/CalculatorIcon";
import CalculateIcon from "@mui/icons-material/Calculate";
import {
  Box,
  Button,
  ButtonGroup,
  Dialog,
  IconButton,
  Skeleton,
  Stack,
  StackProps,
  Tooltip,
  Typography,
} from "@mui/material";
import { useState } from "react";

enum AvailabilityRange {
  THIS_WEEK = "THIS WEEK",
  NEXT_WEEK = "NEXT WEEK",
}

const getHoursCopy = (hours: number | null) => (hours === 1 ? "hour" : "hours");

const ExplanationModal = ({
  isOpen,
  onClose,
  availabilityRange,
  setAvailabilityRange,
  data,
}: {
  isOpen: boolean;
  onClose: () => void;
  availabilityRange: AvailabilityRange;
  setAvailabilityRange: (range: AvailabilityRange) => void;
  data: ProgressOverviewV6;
}) => {
  const weekModalData =
    availabilityRange === AvailabilityRange.THIS_WEEK
      ? {
          completedHours: removeDecimalsIfInt(+data?.completed_hours_this_week),
          lateCancellation: removeDecimalsIfInt(
            +data?.late_cancellation_this_week
          ),
          ptoHours: removeDecimalsIfInt(+data?.pto_hours_this_week),
          completedAdjustedHours: removeDecimalsIfInt(
            +data?.completed_adjusted_hours_this_week
          ),
        }
      : {
          completedHours: removeDecimalsIfInt(+data?.completed_hours_next_week),
          lateCancellation: removeDecimalsIfInt(
            +data?.late_cancellation_next_week
          ),
          ptoHours: removeDecimalsIfInt(+data?.pto_hours_next_week),
          completedAdjustedHours: removeDecimalsIfInt(
            +data?.completed_adjusted_hours_next_week
          ),
        };

  return (
    <Dialog
      open={isOpen}
      onClose={onClose}
      sx={{ "& .MuiDialog-paper": { width: "fit-content", maxWidth: "none" } }}
    >
      <Box sx={{ padding: "24px" }}>
        <Stack
          direction="row"
          spacing={2}
          alignItems="center"
          justifyContent="space-between"
        >
          <Stack direction="row" spacing={2} alignItems="center">
            <CalculatorIcon style={{ width: "24px", height: "24px" }} />
            <Typography sx={{ fontSize: "18px", fontWeight: 700 }}>
              How we calculate completed adjusted hours
            </Typography>
          </Stack>

          <ButtonGroup
            variant="outlined"
            sx={{ mb: 2, display: "flex", justifyContent: "center" }}
          >
            <Button
              onClick={() => setAvailabilityRange(AvailabilityRange.THIS_WEEK)}
              variant={
                availabilityRange === AvailabilityRange.THIS_WEEK
                  ? "contained"
                  : "outlined"
              }
              sx={{
                color: "text.secondary",
                padding: "6px",
                minWidth: "auto",
                backgroundColor:
                  availabilityRange === AvailabilityRange.THIS_WEEK
                    ? "#3F51B514"
                    : "white",
                border: "1px solid #e0e0e0",
                boxShadow: "none",
                fontSize: "12px",
                ":hover": { backgroundColor: "#3F51B514", boxShadow: "none" },
              }}
            >
              This Week
            </Button>
            <Button
              onClick={() => setAvailabilityRange(AvailabilityRange.NEXT_WEEK)}
              variant={
                availabilityRange === AvailabilityRange.NEXT_WEEK
                  ? "contained"
                  : "outlined"
              }
              sx={{
                color: "text.secondary",
                padding: "6px",
                minWidth: "auto",
                backgroundColor:
                  availabilityRange === AvailabilityRange.NEXT_WEEK
                    ? "#3F51B514"
                    : "white",
                border: "1px solid #e0e0e0",
                boxShadow: "none",
                fontSize: "12px",
                ":hover": { backgroundColor: "#3F51B514", boxShadow: "none" },
              }}
            >
              Next Week
            </Button>
          </ButtonGroup>
        </Stack>

        <Box
          display="flex"
          flexDirection="row"
          justifyContent="center"
          alignItems="center"
          marginTop={3}
          sx={{
            gap: { xs: 1, xl: 3 },
            paddingX: { xs: 2, xl: 4 },
            border: "1px solid #CBD5E1",
            padding: "24px",
            borderRadius: "8px",
          }}
        >
          <Stack
            direction="column"
            justifyContent="center"
            alignItems="center"
            textAlign="center"
          >
            <Stack direction="row" alignItems="center">
              <Typography
                sx={{
                  fontSize: { lg: "14px", xs: "10px" },
                  fontWeight: "900",
                }}
              >
                COMPLETED HOURS
              </Typography>
            </Stack>
            <Stack direction="row">
              <Typography
                sx={{ fontSize: { lg: "16px", xs: "12px" }, fontWeight: "900" }}
              >
                {weekModalData.completedHours ?? 0}
              </Typography>
              <Typography
                sx={{
                  marginLeft: "4px",
                  color: "#ABAEB4",
                  fontSize: { lg: "16px", xs: "12px" },
                }}
              >
                {getHoursCopy(weekModalData.completedHours)}
              </Typography>
            </Stack>
          </Stack>

          <Typography
            sx={{ fontSize: "20x", fontWeight: "900", color: "#0F172A59" }}
          >
            <PlusIcon
              style={{ width: "20px", height: "20px", color: "#0F172A59" }}
            />
          </Typography>
          <Stack direction="column" justifyContent="center" alignItems="center">
            <Typography
              sx={{
                fontSize: { lg: "14px", xs: "10px" },
                fontWeight: "900",
                textAlign: "center",
              }}
            >
              LATE CANCELLATION
            </Typography>
            <Stack direction="row">
              <Typography
                sx={{ fontSize: { lg: "16px", xs: "12px" }, fontWeight: "900" }}
              >
                {weekModalData.lateCancellation ?? 0}{" "}
              </Typography>
              <Typography
                sx={{
                  whiteSpace: "nowrap",
                  marginLeft: "4px",
                  color: "#ABAEB4",
                  fontSize: { lg: "16px", xs: "12px" },
                }}
              >
                {getHoursCopy(weekModalData.lateCancellation)}
              </Typography>
            </Stack>
          </Stack>
          <Typography
            sx={{ fontSize: "20x", fontWeight: "900", color: "#0F172A59" }}
          >
            <PlusIcon
              style={{ width: "20px", height: "20px", color: "#0F172A59" }}
            />
          </Typography>
          <Stack direction="column" justifyContent="center" alignItems="center">
            <Typography
              sx={{
                fontSize: { lg: "14px", xs: "10px" },
                fontWeight: "900",
                textAlign: "center",
              }}
            >
              PTO HOURS
            </Typography>
            <Stack direction="row">
              <Typography
                sx={{ fontSize: { lg: "16px", xs: "12px" }, fontWeight: "900" }}
              >
                {weekModalData.ptoHours ?? 0}
              </Typography>

              <Typography
                sx={{
                  whiteSpace: "nowrap",
                  marginLeft: "4px",
                  color: "#ABAEB4",
                  fontSize: { lg: "16px", xs: "12px" },
                }}
              >
                {getHoursCopy(weekModalData.ptoHours)}
              </Typography>
            </Stack>
          </Stack>

          <Bars2Icon
            style={{ width: "20px", height: "20px", color: "#0F172A59" }}
          />

          <Stack
            direction="column"
            justifyContent="center"
            alignItems="center"
            sx={{
              backgroundColor: "#f1f5f9",
              padding: "8px",
              borderRadius: "12px",
            }}
          >
            <Typography
              sx={{
                fontSize: { lg: "14px", xs: "10px" },
                fontWeight: "900",

                textAlign: "center",
              }}
            >
              COMPLETED
              <br />
              ADJUSTED HOURS
            </Typography>
            <Stack direction="row">
              <Typography
                sx={{ fontSize: { lg: "16px", xs: "12px" }, fontWeight: "900" }}
              >
                {weekModalData.completedAdjustedHours ?? 0}
              </Typography>

              <Typography
                sx={{
                  whiteSpace: "nowrap",
                  marginLeft: "4px",
                  color: "#ABAEB4",
                  fontSize: { lg: "16px", xs: "12px" },
                }}
              >
                {getHoursCopy(weekModalData.completedAdjustedHours)}
              </Typography>
            </Stack>
          </Stack>
        </Box>

        <Typography mt={1}>
          Note: We use approximately 5 hours for a PTO day as an estimate.
        </Typography>

        <Box sx={{ marginTop: "14px" }}>
          <Button
            onClick={onClose}
            sx={{
              backgroundColor: "#6e75ef",
              color: "white",
              borderRadius: "12px",
              padding: "14px",
              fontWeight: 600,
              cursor: "pointer",
              "&:hover": { backgroundColor: "#5a63d2" },
            }}
          >
            Got it, thanks
          </Button>
        </Box>
      </Box>
    </Dialog>
  );
};

const HoursNeededToHitCommitedHours = ({
  hoursNeeded,
}: {
  hoursNeeded: number | null;
}) => {
  if (!hoursNeeded) {
    return (
      <Typography variant="body2" fontSize="12px">
        likely to reach commited hours! 🎉
      </Typography>
    );
  }

  return (
    <Typography variant="body2" fontSize="12px">
      <Box component="span" color="#3E7FDF" fontWeight="bold" fontSize="14px">
        {hoursNeeded}
      </Box>{" "}
      to reach your commited hours
    </Typography>
  );
};

const RecommendedHours = ({
  clinicianEmail,
  scrollToBottom,
  showMemberFinder,
}: {
  clinicianEmail: string | null;
  scrollToBottom: () => void;
  showMemberFinder?: boolean;
}) => {
  const { data, error, isLoading } = useProgressOverviewV6(
    {
      staleTime: clinicianEmail ? 0 : Infinity,
    },
    clinicianEmail ?? null
  );

  const [isExplanationModalOpen, setIsExplanationModalOpen] = useState(false);

  const [availabilityRange, setAvailabilityRange] = useState<AvailabilityRange>(
    AvailabilityRange.THIS_WEEK
  );

  const hoursMapping = {
    [AvailabilityRange.THIS_WEEK]: {
      selectTitle: "This week's forecast",
      completedHoursTitle: "Completed",
      completedHours: removeDecimalsIfInt(+data?.completed_hours_this_week),
      lateCancellation: removeDecimalsIfInt(+data?.late_cancellation_this_week),
      ptoHours: removeDecimalsIfInt(+data?.pto_hours_this_week),
      completedAdjustedHours: removeDecimalsIfInt(
        +data?.completed_adjusted_hours_this_week
      ),
      scheduledHours: removeDecimalsIfInt(+data?.scheduled_hours_this_week),
      scheduledHoursTitle: "Scheduled",
      cancelledHoursTitle: "# Est. Cancels",
      projectedCancelled: removeDecimalsIfInt(
        +data?.projected_cancelled_this_week
      ),
      projectedCompleted: removeDecimalsIfInt(
        +data?.projected_completed_this_week
      ),
      projectedCompletedTitle: "Likely to Complete",
      moreHoursNeeded: removeDecimalsIfInt(+data?.more_hours_needed_this_week),
      moreHoursNeededBonus: removeDecimalsIfInt(
        +data?.more_hours_needed_bonus_this_week
      ),
      targetHoursBonus: removeDecimalsIfInt(
        +data?.bonus_target_hours_this_week
      ),
      targetHours: removeDecimalsIfInt(+data?.committed_hours),
    },
    [AvailabilityRange.NEXT_WEEK]: {
      selectTitle: "Next week's forecast",
      completedHoursTitle: "Completed",
      completedHours: removeDecimalsIfInt(+data?.completed_hours_next_week),
      lateCancellation: removeDecimalsIfInt(+data?.late_cancellation_next_week),
      ptoHours: removeDecimalsIfInt(+data?.pto_hours_next_week),
      completedAdjustedHours: removeDecimalsIfInt(
        +data?.completed_adjusted_hours_next_week
      ),
      scheduledHours: removeDecimalsIfInt(+data?.scheduled_hours_next_week),
      scheduledHoursTitle: "Scheduled",
      cancelledHoursTitle: "# Est. Cancels",
      projectedCancelled: removeDecimalsIfInt(
        +data?.projected_cancelled_next_week
      ),
      projectedCompleted: removeDecimalsIfInt(
        +data?.projected_completed_next_week
      ),
      projectedCompletedTitle: "Likely to Complete",
      moreHoursNeeded: removeDecimalsIfInt(+data?.more_hours_needed_next_week),
      moreHoursNeededBonus: removeDecimalsIfInt(
        +data?.more_hours_needed_bonus_next_week
      ),
      targetHoursBonus: removeDecimalsIfInt(
        +data?.bonus_target_hours_next_week
      ),
      targetHours: removeDecimalsIfInt(+data?.committed_hours_next_week),
    },
  };

  return (
    <>
      {
        // eslint-disable-next-line no-nested-ternary
        isLoading ? (
          <>
            <Skeleton width="100%" height="80px" />

            <Skeleton width="100%" height="80px" />
          </>
        ) : error ? (
          <Typography color="error.main" variant="body2">
            {error.message}
          </Typography>
        ) : (
          <Stack flex={1}>
            <Stack direction="row" justifyContent="space-between">
              <Stack direction="row" alignItems="flex-start" spacing={0.5}>
                <Typography
                  sx={{
                    fontSize: "14px",
                    color: "#ABAEB4",
                    fontWeight: "700",
                  }}
                >
                  SESSION HOURS
                </Typography>
                <Tooltip
                  arrow
                  title="Based on past attendance of your members, you may need more hours available to meet your goal for weekly therapy hours."
                  placement="top"
                >
                  <InformationCircleIcon
                    style={{
                      color: "#0F172A59",
                      height: "20px",
                      width: "20px",
                    }}
                  />
                </Tooltip>
              </Stack>
              <MuiLink
                href="/utilization-dashboard"
                target="_blank"
                underline="none"
                sx={{ color: "#2E8EFF", fontSize: "14px", fontWeight: "bold" }}
              >
                View All
              </MuiLink>
            </Stack>

            <Stack
              direction="row"
              spacing={1}
              alignItems="top"
              sx={{ marginTop: "8px", paddingBottom: 1 }}
            >
              <Typography
                sx={{ fontSize: "12px", color: "text.secondary", flex: 0.5 }}
              />
              <Stack
                direction="row"
                alignItems="top"
                justifyContent="center"
                sx={{ flex: 0.5, whiteSpace: "nowrap" }}
              >
                <Typography
                  sx={{
                    fontSize: "12px",
                    color: "text.secondary",
                    alignSelf: "center",
                  }}
                >
                  COMPLETED
                </Typography>

                <IconButton
                  size="small"
                  type="button"
                  onClick={() => setIsExplanationModalOpen(true)}
                  sx={{
                    alignSelf: "flex-start",
                    paddingY: 0,
                  }}
                >
                  <Box
                    sx={{
                      width: "16px",
                      height: "20px",
                      alignItems: "center",
                    }}
                  >
                    <CalculateIcon
                      style={{
                        width: "100%",
                        height: "100%",
                        color: "action.active",
                      }}
                    />
                  </Box>
                </IconButton>
              </Stack>
              <Typography sx={{ flexShrink: 0, alignSelf: "flex-start" }}>
                +
              </Typography>
              <Typography
                sx={{
                  fontSize: "12px",
                  color: "text.secondary",
                  flex: 0.5,
                  alignSelf: "center",
                }}
              >
                SCHEDULED
              </Typography>
              <Typography sx={{ flexShrink: 0 }}>-</Typography>
              <Typography
                sx={{
                  fontSize: "12px",
                  color: "text.secondary",
                  flex: 0.5,
                  whiteSpace: "nowrap",
                  alignSelf: "center",
                }}
              >
                EST. CANCELS
              </Typography>
              <Typography sx={{ flexShrink: 0 }}>=</Typography>
              <Typography
                sx={{
                  fontSize: "12px",
                  color: "text.secondary",
                  flex: 1,
                  textAlign: "center",
                  whiteSpace: "nowrap",
                  alignSelf: "center",
                }}
              >
                LIKELY TO COMPLETE
              </Typography>
            </Stack>

            {Object.entries(hoursMapping).map(([key, week]) => (
              <Stack
                direction="row"
                alignItems="flex-start"
                spacing={0.5}
                sx={{ marginTop: "16px" }}
                key={key}
              >
                <Typography
                  sx={{
                    fontSize: "14px",
                    color: "text.secondary",
                    flex: 0.5,
                    textAlign: "left",
                  }}
                >
                  {key}
                </Typography>
                <Typography
                  sx={{
                    fontSize: "14px",
                    fontWeight: "bold",
                    flex: 0.5,
                    textAlign: "top",
                  }}
                >
                  {week.completedAdjustedHours}
                </Typography>
                <Typography
                  sx={{ fontSize: "14px", fontWeight: "bold", flex: 0.5 }}
                >
                  {week.scheduledHours}
                </Typography>
                <Typography
                  sx={{ fontSize: "14px", fontWeight: "bold", flex: 0.5 }}
                >
                  {week.projectedCancelled}
                </Typography>

                <Stack
                  sx={{
                    flex: 1,
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    justifyContent: "flex-start",
                  }}
                >
                  <Box
                    sx={{
                      backgroundColor:
                        week.projectedCompleted >= week.targetHours
                          ? "#E8F5E9"
                          : "#FEEBEE",
                      color:
                        week.projectedCompleted >= week.targetHours
                          ? "#4CAF50"
                          : "#F44336",
                      paddingX: "8px",
                      paddingY: "4px",
                      borderRadius: "8px",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      fontSize: "14px",
                      fontWeight: "bold",
                      minWidth: "32px",
                      textAlign: "center",
                      mt: "-4px",
                    }}
                  >
                    {week.projectedCompleted}
                  </Box>

                  <>
                    <HoursNeededToHitCommitedHours
                      hoursNeeded={week.moreHoursNeeded}
                    />
                    {showMemberFinder && week.moreHoursNeeded > 0 && (
                      <Typography
                        onClick={() => scrollToBottom()}
                        sx={{
                          fontSize: "12px",
                          color: "blue",
                          textDecoration: "underline",
                          cursor: "pointer",
                          marginTop: "4px",
                        }}
                      >
                        Member Finder
                      </Typography>
                    )}
                  </>
                </Stack>
              </Stack>
            ))}
          </Stack>
        )
      }

      <ExplanationModal
        isOpen={isExplanationModalOpen}
        onClose={() => setIsExplanationModalOpen(false)}
        availabilityRange={availabilityRange}
        setAvailabilityRange={setAvailabilityRange}
        data={data}
      />
    </>
  );
};

interface ClinicianRecommendedHoursProps extends StackProps {
  clinicianEmail?: string | null;
  scrollToBottom?: () => void;
  showMemberFinder?: boolean;
}
const ClinicianRecommendedHoursV2 = ({
  clinicianEmail,
  scrollToBottom,
  showMemberFinder = false,
}: ClinicianRecommendedHoursProps) => {
  return (
    <>
      <Stack
        padding={2}
        flex={1}
        sx={{
          textAlign: "center",
          border: "1px solid #CBD5E1",
          borderRadius: "8px",
        }}
      >
        <RecommendedHours
          clinicianEmail={clinicianEmail ?? null}
          scrollToBottom={scrollToBottom}
          showMemberFinder={showMemberFinder}
        />
      </Stack>
    </>
  );
};

export default ClinicianRecommendedHoursV2;
